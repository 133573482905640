export default {
  sections: object => {
    return [
      {
        editable: true,
        title: 'адрес',

        params: [
          {
            title: '',
            key: 'address_district_id'
          },
          {
            title: 'ПОЯСНЕНИЯ К АДРЕСУ',
            key: 'address_info'
          },
          {
            map: true,
            title: 'НА КАРТЕ',
            key: 'geo_polygon'
          }
        ]
      },
      {
        editable: true,
        title: 'Общие сведения',
        params: [
          {
            title: 'тип нарушения',
            key: 'violation_type_id'
          },
          {
            title: 'собственник известен',
            key: 'owner_is_known'
          },
          {
            title: 'ИНФОРМАЦИЯ О СОБСТВЕННИКЕ',
            key: 'owner_info'
          },
          {
            title: 'тип объекта и вид объекта',
            key: 'obj_type_id'
          },
          {
            title: 'КАДАСТРОВЫЙ НОМЕР ЗЕМЕЛЬНОГО УЧАСТКА',
            key: 'land_cadastral_number'
          },
          {
            title: 'Площадь земельного участка, кв.м',
            key: 'land_area'
          },
          {
            title: 'Количество машино-мест, всего',
            key: 'parking_place_total_cnt'
          },
          {
            title:
              'Количество машино-мест, в т.ч. для парковки специальных автотранспортных средств инвалидов',
            key: 'parking_place_with_disabled_cnt'
          }
        ]
      },
      {
        title:
          'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ НЕЗАКОННОГО ОБЪЕКТА',
        params: [
          {
            title: 'НОМЕР',
            key: 'audit_doc_number'
          },
          {
            title: 'ДАТА ВЫЯВЛЕНИЯ',
            key: 'audit_doc_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_audit_doc_files'
          },
          {
            title: 'ФОТОГРАФИИ ОБЪЕКТА',
            key: 'file_audit_doc_photos'
          }
        ]
      },
      {
        editable: true,
        title: 'Обеспечительные меры',
        params: [
          {
            title: 'НАЛОЖЕНЫ ЛИ ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ',
            key: 'warranty_measures_taken'
          },
          {
            title: 'КОММЕНТАРИЙ',
            key: 'warranty_measures_info'
          }
        ]
      },
      {
        editable: true,
        title: 'Добровольный демонтаж',
        params: [
          {
            title: 'ПЕРИОД, ОТВЕДЕННЫЙ НА ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ ОБЪЕКТА',
            key: 'title'
          },
          {
            title: 'НАЧАЛО',
            key: 'remove_voluntary_date_from'
            //unset_border: ''
          },
          {
            title: 'ОКОНЧАНИЕ',
            key: 'remove_voluntary_date_to'
          },
          {
            title:
              'СВЕДЕНИЯ ОБ АКТЕ ТЕРРИТОРИАЛЬНОГО ОРГАНА АДМИНИСТРАЦИИ ГОРОДА О ПРОДЛЕНИИ',
            key: 'title'
          },
          {
            title: 'НАИМЕНОВАНИЕ',
            key: 'remove_ext_doc_type_name'
          },
          {
            title: '№',
            key: 'remove_ext_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_ext_doc_date'
          },
          {
            title: 'СРОК ПРОДЛЕНИЯ',
            key: 'remove_ext_date_to'
          },
          {
            title: 'СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'remove_voluntary_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_voluntary_doc_date'
          },
          {
            title:
              'РЕЗУЛЬТАТЫ ПРОВЕРКИ ИСПОЛНЕНИЯ ТРЕБОВАНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ',
            key: 'remove_voluntary_is_done'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_remove_voluntary_files'
          },
          {
            title: 'ФОТОГРАФИИ',
            key: 'file_remove_voluntary_photos'
          }
        ]
      },
      {
        editable: true,
        title: 'Принудительный демонтаж',
        params: [
          {
            title:
              'РЕКВИЗИТЫ РАСПОРЯЖЕНИЯ ГЛАВЫ ТЕРРИТОРИАЛЬНОГО ОРГАНА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'title'
          },
          {
            title: '№ СЭД',
            key: 'remove_force_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_force_doc_date'
          },
          {
            title: 'СВЕДЕНИЯ ОБ ОФИЦИАЛЬНОМ ОПУБЛИКОВАНИИ',
            key: 'title'
          },
          {
            title: '№ ОФИЦИАЛЬНОГО БЮЛЛЕТЕНЯ ОРГАНОВ МЕСТНОГО САМОУПРАВЛЕНИЯ',
            key: 'remove_force_bulletin_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_force_bulletin_date'
          },
          {
            title: 'ФАЙЛЫ',
            key: 'file_remove_force_files'
          }, //[]
          {
            title: 'ДАТА ПЛАНИРУЕМОГО ДЕМОНТАЖА ОБЪЕКТА',
            key: 'remove_plan_date'
          },
          {
            title: 'ДАТА ФАКТИЧЕСКОГО ДЕМОНТАЖА ОБЪЕКТА',
            key: 'remove_actual_date'
          }
        ]
      },

      {
        editable: true,
        title: 'ПОВТОРНЫЕ СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
        params: [
          {
            title: 'ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ',
            key: 'sec_remove_voluntary_is_done'
          },
          {
            _if: object.sec_remove_voluntary_doc_number,
            title: '№',
            key: 'sec_remove_voluntary_doc_number'
          },
          {
            _if: object.sec_remove_voluntary_doc_number,
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'sec_remove_voluntary_doc_date'
          },
          {
            files: true,
            _if: object.sec_remove_voluntary_doc_number,
            title: 'ДОКУМЕНТЫ',
            key: 'file_sec_remove_voluntary_files'
          },
          {
            _if: object.sec_remove_voluntary_doc_number,
            title: 'ФОТОГРАФИИ',
            key: 'file_sec_remove_voluntary_photos'
          }
        ]
      },
      {
        title: 'ПЕРЕМЕЩЕНИЕ И ХРАНЕНИЕ ОБЪЕКТА',
        params: [
          {
            title: 'СВЕДЕНИЯ ОБ АКТЕ О ДЕМОНТАЖЕ И ПЕРЕМЕЩЕНИИ ОБЪЕКТА',
            key: 'title'
          },
          {
            title: '№',
            key: 'remove_act_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'remove_act_date'
          },
          {
            files: true,
            title: 'документы',
            key: 'file_remove_act_files'
          },
          {
            title: 'фотографии',
            key: 'file_remove_act_photos'
          },
          {
            title: 'СВЕДЕНИЯ О ХРАНЕНИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА',
            key: 'title'
          },
          {
            _if: object.keep_place_id,
            title: 'АДРЕС ХРАНЕНИЯ ОБЪЕКТА',
            key: 'place_street&place_house'
          },
          {
            _if: object.keep_place_id,
            title: 'ЛИЦО, ОСУЩЕСТВЛЯЮЩЕЕ ХРАНЕНИЕ',
            key: 'responsible_name'
          },
          {
            _if: object.keep_date,
            title: 'ОКОНЧАНИЕ СРОКА ХРАНЕНИЯ',
            key: 'keep_date'
          },
          {
            title: 'СВЕДЕНИЯ О ВОЗВРАТЕ ДЕМОНТИРОВАННОГО ОБЪЕКТА ВЛАДЕЛЬЦУ',
            key: 'title'
          },

          {
            title: 'Объект выдан владельцу',
            key: 'object_issued_to_owner'
          },
          {
            title: 'Дата выдачи объекта',
            key: 'object_issued_to_owner_date'
          },
          {
            title: '№ акта сдачи-приемки объекта',
            key: 'object_issued_to_owner_act_number'
          },
          {
            title: 'Дата акта сдачи-приемки объекта',
            key: 'object_issued_to_owner_act_date'
          },

          {
            files: true,
            title: 'Акт сдачи-приемки',
            key: 'file_object_issued_to_owner_files'
          },
          {
            title:
              'ИНФОРМАЦИЯ О ЗАХОРОНЕНИИ ЛИБО УТИЛИЗАЦИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА',
            key: 'title'
          },
          {
            title: '№',
            key: 'utilization_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'utilization_date'
          },
          {
            files: true,
            title: 'Документы',
            key: 'file_utilization_files'
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ВОЗМЕЩЕНИИ ПОНЕСЕННЫХ РАСХОДОВ',
        params: [
          {
            title:
              'СУММА ФАКТИЧЕСКИ ПОНЕСЕННЫХ РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ',
            key: 'remove_cost_actual'
          },
          {
            title:
              'СУММА ВОЗМЕЩЕННЫХ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ,ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА, РУБ.',
            key: 'remove_cost_refunded'
          },
          {
            title:
              'ДАТА ВОЗМЕЩЕНИЯ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ,ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА',
            key: 'remove_cost_refund_date'
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ДОГОВОРЕ АРЕНДЫ',
        params: [
          {
            title:
              'СВЕДЕНИЯ О ДОГОВОРЕ АРЕНДЫ И ВИДЕ РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ ЗЕМЕЛЬНОГО УЧАСТКА',
            key: 'agreement_number'
          },
          {
            title: '№',
            key: 'agreement_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'agreement_date'
          },
          {
            title: 'Дата окончания',
            key: 'agreement_end_date'
          },

          {
            title: 'Информация о продлении',
            key: 'agreement_extension_date'
          },
          {
            title: 'Сведения об арендаторе',
            key: 'information_about_tenant'
          },
          {
            title: 'Сведения о нарушениях',
            key: 'information_about_violations'
          },
          {
            title: 'Вид разрешенного использования земельного участка',
            key: 'agreement_land_usage_type_id'
          }
        ]
      }
      //{ title: "", params: [{ title: "", key: "" }] },
    ];
  }
};
